import React, { useEffect, useState } from 'react'
// import NewsProps from '../Pages/NewsProps'

import { Link } from 'react-router-dom'
import { getNews } from '../Service/Api';
import LazyLoad from 'react-lazyload';
const HomeNews = () => {
  const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getNews();
        console.log(topperData)
        setData(topperData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        // Handle error (e.g., show error message)
      } finally {
        setLoading(false); // Set loading to false after data fetching is complete
      }
    };
    fetchData();
  }, []);
  
  return (
   <>
    {data.length > 0 ? (data.map((item, index) => (
          <div className="homenews" key={index}>
          <div className="news_tag">
          {item.attachments.length > 0 && (   <Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank">
                 <i className="fa fa-paperclip colr_1" aria-hidden="true"></i>
              </Link>)}
          </div>
       <LazyLoad>   <img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
          <h3>{item.title}</h3>
          <p>{item.description}</p>
       </div>
    // <NewsProps href="link" title={item.title} desc={item.description} image={"https://webapi.entab.info/api/image/LVISGN/public/Images/gal.jpeg"}  key={index} />
    ))
    
    ):(<div className="homenews">
    
    <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/gal.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
    <h3>News Title</h3>
    <p>Stay tuned for more information about latest updates.</p>
 </div>)}
    {/* <NewsProps href="link" title="Headlines - 1" desc="Stay tuned for more information about latest updates." image={"https://webapi.entab.info/api/image/LVISGN/public/Images/gal.jpeg"}/>
    <NewsProps href="link" title="Headlines - 1" desc="Stay tuned for more information about latest updates." image={"https://webapi.entab.info/api/image/LVISGN/public/Images/gal.jpeg"}/>
    <NewsProps href="link" title="Headlines - 1" desc="Stay tuned for more information about latest updates." image={"https://webapi.entab.info/api/image/LVISGN/public/Images/gal.jpeg"}/> */}
   </>
  )
}

export default HomeNews
