import React, { useEffect, useRef, useState } from 'react'
import { getHighlight } from '../Service/Api';
import { Link } from 'react-router-dom'
const Highlights = () => {
  const marqueeRef = useRef(null);
  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };  
  const handleMouseOut = () => {
    marqueeRef.current.start();
  };
  const [data, setData] = useState([]);
useEffect(() => {
  const getData = async () => {
    const data = await getHighlight();
    console.log(data);
    setData(data);
  };
  getData();
}, []);


const emptyArray = [
  { description: "Stay tuned for latest updates" }
];

  return (
    <>
    <div className="highligh_sec">
    <h4><Link to="Highlights"><span className="normal_view">Highlights : </span><span  className="mob_view"><i className="fas fa-angle-double-right"></i></span></Link></h4>
    <ul>
 <li><Link to="https://www.facebook.com/lvisne" target="_blank"><i className="fab fa-facebook-f"></i></Link></li>
    <li><Link to="https://youtube.com/@lotusvalleyne?si=-pRsanW9eWfeoZhx " target="_blank"><i className="fab fa-youtube"></i></Link></li>
    <li><Link to="https://instagram.com/lvis_ne?utm_source=ig_profile_share&amp;igshid=1xz6aqxjbdmzr" target="_blank"><i className="fab fa-instagram"></i></Link></li>
  <li><Link to="https://www.linkedin.com/company/lotus-valley-international-school-noida-extension/?viewAsMember=true" target="_blank"><i className="fab fa-linkedin"></i></Link></li> 
      </ul>
    
    <marquee
          onMouseOver={(event) => event.currentTarget.stop()} 
          onMouseOut={(event) => event.currentTarget.start()}>
      {data.length > 0 ? ( data.map((item, index) => (
<p key={index}>
      {item.description} 
      {item.attachments.length > 0 && (
        <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank"><i className="fa fa-paperclip"></i> </Link>
      )} 
</p>
        ))
        ) : (
          emptyArray.map((data, index) => (
            <a key={index}>{data.description}</a>
          ))
          )}
          {/* Stay tuned for more information about latest updates. 
         <Link to="#"  target="_blank"><i className="fa fa-paperclip"></i> </Link> */}
     
      </marquee>
    </div>
    </>
  )
}

export default Highlights

