import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
import LazyLoad from 'react-lazyload'
const StudentExchangeProgramm = () => {
  return (
    <>
    <HeadTag title="Assemblies" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Academics <i className="fas fa-angle-right"></i>  Student Exchange Programm </span></h5>
        <h2> Student Exchange Programm </h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <p><b>Name Of Country : Japan</b></p>
            </div>

        </div>
        <div class="popup-gallery socialWork mt-4">
                <div class="row">

                  <div class="col-lg-4 col-md-4"><a href="/Images/japan1.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan2.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan3.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan4.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan5.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan5.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan6.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan6.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan7.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan7.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan8.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan8.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan9.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan9.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan10.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan10.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan11.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan11.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan12.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan12.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan13.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan13.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan14.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan14.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan15.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan15.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan16.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan16.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan17.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan17.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan18.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan18.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan19.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan19.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan20.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan20.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan21.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan21.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan22.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan22.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan23.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan23.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan24.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan24.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan25.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan25.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan26.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan26.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan27.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan27.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan28.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan28.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan29.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan29.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan30.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan30.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan31.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan31.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan32.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan32.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan33.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan33.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan34.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan34.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan35.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan35.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan36.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan36.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan37.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan37.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan38.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan38.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan39.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan39.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan40.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan40.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan41.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan41.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  {/* <div class="col-lg-4 col-md-4"><a href="/Images/japan41.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan41.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div> */}
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan42.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan42.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan43.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan43.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan44.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan44.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan45.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan45.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan46.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan46.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan47.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan47.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan48.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan48.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan49.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan49.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan50.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan50.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan51.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan51.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan52.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan52.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-4 col-md-4"><a href="/Images/japan53.jpg" data-magnify="gallery" data-src="" data-group="a"><LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/japan53.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>

                </div>
              </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default StudentExchangeProgramm
