import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const HobbyClubs = () => {
  return (
    <>
    <HeadTag title="Hobby Clubs" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular  <i className="fas fa-angle-right"></i>  Hobby Clubs </span></h5>
        <h2>  Hobby Clubs </h2>
        </div>
        <p>We at LVIS, Noida Extension believe that sports and co-curricular activities play a vital role in the holistic development of children and also form the basis of pre-vocational skills.The school has introduced various Hobby Clubs from the academic session 2018-19 for Classes I-IX. These Clubs will help in identifying and fostering the creativity of students, beyond academics.</p>
        <div className="row">
         <div className="col-lg-6 col-md-12">
        <h3>Classes I & II</h3>
        <div className="innertable">
        <table width="100%;" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody className="text-left">
            <tr>
              <td>1</td>
              <td>Abacus Whiz</td>
            </tr>      
            <tr className="success">
              <td>2</td>
              <td>Happy Feet(Indian Dance)</td>
            </tr>
            <tr className="danger">
              <td>3</td>
              <td>Happy Feet(Western Dance)</td>
            </tr>
            <tr className="info">
              <td>4</td>
              <td>Spotlight</td>
            </tr>
            <tr className="warning">
              <td>5</td>
              <td>Melody  Makers</td>
            </tr>
            <tr className="active">
              <td>6</td>
              <td>Steam</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Better Me</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Creative Hive</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Fitness Revolution</td>
            </tr>
             <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>      
          </tbody>
        </table>
        </div>
        </div>
        <div className="col-lg-6 col-md-12">
        <h3>Classes III</h3>
        <div className="innertable">
        <table width="100%;" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody className="text-left">
            <tr>
              <td>1</td>
              <td>'ABACUS' (PAID ACTIVITY)</td>
            </tr>      
            <tr className="success">
              <td>2</td>
              <td>'BEYOND THE STARS' - ASTRONOMY CLUB (PAID ACT)</td>
            </tr>
            <tr className="danger">
              <td>3</td>
              <td>'Nature Nurture'- Environmental Club</td>
            </tr>
            <tr className="info">
              <td>4</td>
              <td>Art Attack'- Art Club</td>
            </tr>
            <tr className="warning">
              <td>5</td>
              <td>'Crafty Hands'- Origami Club</td>
            </tr>
            <tr className="active">
              <td>6</td>
              <td>'Culinary Delight'- Fireless Cooking Club</td>
            </tr>
            <tr>
              <td>7</td>
              <td>'Expressions' - Theatre and Drama Club</td>
            </tr>
            <tr>
              <td>8</td>
              <td>'Dancepiration'- Dance Club</td>
            </tr>
            <tr>
              <td>9</td>
              <td>'Bits and Bytes'- ICT Club</td>
            </tr>
            <tr>
              <td>10</td>
              <td>'Absolute Fit'- Fitness Club</td>
            </tr>
             <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>      
          </tbody>
        </table>
        </div>
        </div>
        <div className="col-lg-6 col-md-12">
        <h3>Classes IV to V</h3>
        <div className="innertable">
        <table width="100%;" cellpadding="0" cellspacing="0">
      
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody className="text-left">
            <tr>
              <td>1</td>
              <td>'ABACUS' (PAID ACTIVITY)</td>    
            </tr>      
            <tr className="success">
              <td>2</td>
              <td>'BEYOND THE STARS' - ASTRONOMY CLUB (PAID ACTIVITY)</td>
            </tr>
            <tr className="danger">
              <td>3</td>
              <td>POTTERY AND CLAY MODELLING CLUB (PAID ACTIVITY)</td>
            </tr>
            <tr className="info">
              <td>4</td>
              <td>STEM ROBO CLUB (PAID ACTIVITY) (Grade V only)</td>
            </tr>
            <tr className="warning">
              <td>5</td>
              <td>PUBLIC SPEAKING AND DEBATE CLUB (PAID ACTIVITY)</td>
            </tr>
            <tr className="active">
              <td>6</td>
              <td>'EXPRESSIONS'- THEATRE AND DRAMA CLUB (PAID ACTIVITY)</td>
            </tr>
               <tr>
              <td>7</td>
              <td>'Parampara'- Heritage Club</td>
            </tr> 
               <tr>
              <td>8</td>
              <td>'Nature Nurture'-Environmental Club</td>
            </tr>      
               <tr>
              <td>9</td>
              <td>'Art Attack'- Art Club</td>
            </tr>      
               <tr>
              <td>10</td>
              <td>'Culinary Delight'- Fireless Cooking Club</td>
            </tr>      
               <tr>
              <td>11</td>
              <td>'Dancepiration'- Dance Club (Grade IV only)</td>
            </tr>      
          </tbody>
        </table>
        </div>
        </div>
        <div className="col-lg-6">
        <h3>Classes VI TO X</h3>
        <div className="innertable">
        <table width="100%;" cellpadding="0" cellspacing="0">
        
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody className="text-left">
          <tr>
              <td colSpan="2"><b>PAID CLUB ACTIVITIES</b></td>
             </tr>   
            <tr>
              <td>1</td>
              <td>Vedic Math Club: Math Mantra</td>      
            </tr>      
            <tr className="success">
              <td>2</td>
              <td>Pottery and Clay Modelling Club: Clay Crafters</td>
            </tr>
            <tr className="danger">
              <td>3</td>
              <td>STEM Robo Club: Tech Titans</td>
            </tr>
            <tr className="info">
              <td>4</td>
              <td>Space and Astronomy Club: Beyond the Stars</td>
            </tr>
            <tr className="warning">
              <td>5</td>
              <td>Model United Nations (MUN): MUN Saga</td>
            </tr>
            <tr className="active">
              <td>6</td>
              <td>Public Speaking and Debate Club: Orator's Oasis</td>
            </tr>
               <tr>
              <td>7</td>
              <td>Theatre and Drama Club: Expressions</td>
            </tr> 
               <tr>
              <td>8</td>
              <td>Photography Club: Clicked!</td>
             </tr>  
             
               <tr>
              <td colSpan="2"><b>NON-PAID CLUB ACTIVITIES</b></td>
             </tr>   
               <tr>
              <td>1</td>
              <td>Yoga club: Body, Mind, Soul</td>
             </tr> 
             <tr>
              <td>2</td>
              <td>Eco Club: Prakriti</td>
             </tr> 
             <tr>
              <td>3</td>
              <td>Culinary Club: Flavourful Fusion</td>
             </tr> 
             <tr>
              <td>4</td>
              <td>Western Instrumental Music Club: Jazz It!</td>
             </tr> 
          </tbody>
        </table>
        </div>
        </div>
      </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default HobbyClubs
