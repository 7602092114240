import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
import LazyLoad from 'react-lazyload'
const Result = () => {
    return (
        <>
            <HeadTag title="Hobby Clubs" />
            <Highlights />
            <InnerHaeder />
            <div className="inner_page">
                <div className="container-fluid flwidth">
                    <div className="bgimage">
                        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>Academics <i className="fas fa-angle-right"></i>  Result </span></h5>
                        <h2> Result </h2>
                    </div>
 
                    <div class="popup-gallery socialWork mt-4">
                <div class="row">

                  <div class="col-lg-6 col-md-6"><a href="/Images/result1.jpg" data-magnify="gallery" data-src="" data-group="a">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/result1.jpg" class="img-fluid h-auto" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                  <div class="col-lg-6 col-md-6"><a href="/Images/result2.jpg" data-magnify="gallery" data-src="" data-group="a">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/result2.jpg" class="img-fluid h-auto" alt="Lotus Valley International School, Greater Noida" /></LazyLoad></a></div>
                 
                </div> 

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Result
