import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
const Eventsprops = (props) => {
  return (
   <>
   <div className="col-lg-12 col-md-12">
    <div className="draw-border">
         
           <LazyLoad>
            <img src= {props.img} className="img-fluid" alt="Lotus Valley International School, Greater Noida"/>
            </LazyLoad>
            <h3>{props.title}</h3>
            <div className="main">
                <p>{props.details}</p>
                <div className="sub">
                <ul>
                    <li><i className="fa fa-calendar" aria-hidden="true"></i> &nbsp; {props.detailsmonth}</li>
                    <li><i className="fa fa-clock" aria-hidden="true"></i> &nbsp; {props.detailshour}</li>
                    <li><i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;  {props.detailslocation}</li>
                </ul>
                <div className="clr"></div>
                </div>
            </div>
        </div>
    </div>
   </>
  )
}

export default Eventsprops
