import React, { useEffect, useRef, useState } from 'react'
import { getTestimonial } from '../Service/Api';
import Slider from "react-slick";
const Testimonials = () => {

    const [data, setData] = useState([]);
    useEffect(() => {
        const getData = async () => {
            const data = await getTestimonial();
            console.log(data);
            setData(data);
        };
        getData();
    }, []);
  
    const emptyArray = [
      { description: "Stay tuned for latest updates" }, 
  ];

  const settings = { 
    infinite: false,
    arrows:false,
    dots:false,
    speed: 500,
    slidesToShow: 4,
    margin: 20,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
       {
          breakpoint: 1200,
          settings: {
             slidesToShow: 3,
             slidesToScroll: 1,
             infinite: true,
             dots: true
          }
       },
       {
          breakpoint: 1000,
          settings: {
             slidesToShow: 2,
             slidesToScroll: 1,
             infinite: true,
             dots: true
          }
       },
       {
          breakpoint: 700,
          settings: {
             slidesToShow: 1,
             slidesToScroll: 1,
             infinite: true,
             dots: true
          }
       }
    ]
 };
    return (
        <>
           <Slider {...settings}> 
            {data.length > 0 ? (
    data.map((item, index) => (
                <div className='item' key={index}>
                    <div className="testi_1">
                        <div className="data_sec">
                        <p>{item.discription}</p>
                     
                        </div>
                        <h4>{item.name}</h4>
                    </div>
                </div>
                  ))
                  ) : (
                    emptyArray.map((data, index) => (
                    <div className='item'>
                    <div className="testi_1">
                        <div className="data_sec">
                            <p>We just wanted to reach out to you all today, and let you know how amazing a job all of you are doing. These days of e-learning have allowed us parents to observe the efforts put in by all of you from close quarters. We appreciate how you've tirelessly ventured out into this whole new world, reaching out to the kids with patience, warmth and love. We are so so grateful.</p>
                        </div>
                        <h4>Name</h4>
                    </div>
                </div>
                   ))
                   )}
                {/* <div className='item'>
                    <div className="testi_1">
                        <div className="data_sec">
                            <p>We just wanted to reach out to you all today, and let you know how amazing a job all of you are doing. These days of e-learning have allowed us parents to observe the efforts put in by all of you from close quarters. We appreciate how you've tirelessly ventured out into this whole new world, reaching out to the kids with patience, warmth and love. We are so so grateful.</p>
                        </div>
                        <h4>Name</h4>
                    </div>
                </div>
                <div className='item'>
                    <div className="testi_1">
                        <div className="data_sec">
                            <p>We just wanted to reach out to you all today, and let you know how amazing a job all of you are doing. These days of e-learning have allowed us parents to observe the efforts put in by all of you from close quarters. We appreciate how you've tirelessly ventured out into this whole new world, reaching out to the kids with patience, warmth and love. We are so so grateful.</p>
                        </div>
                        <h4>Name</h4>
                    </div>
                </div>
                <div className='item'>
                    <div className="testi_1">
                        <div className="data_sec">
                            <p>We just wanted to reach out to you all today, and let you know how amazing a job all of you are doing. These days of e-learning have allowed us parents to observe the efforts put in by all of you from close quarters. We appreciate how you've tirelessly ventured out into this whole new world, reaching out to the kids with patience, warmth and love. We are so so grateful.</p>
                        </div>
                        <h4>Name</h4>
                    </div>
                </div> */}
            </Slider>
        </>
    )
}

export default Testimonials
