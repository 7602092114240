import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const StudentStrength = () => {
  return (
    <>
      <HeadTag title="Student Strength" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us <i className="fas fa-angle-right"></i>  Student Strength </span></h5>
            <h2>Student Strength (2024-25)</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="innertable">
                <table width="100%" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th>CLASS </th>
                      <th>NUMBER OF SECTIONS </th>
                      <th>NUMBER OF STUDENTS </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>NURSERY</td>
                      <td>6</td>
                      <td>179</td>
                    </tr>
                    <tr>
                      <td>LKG</td>
                      <td>2</td>
                      <td>56</td>
                    </tr>
                    <tr>
                      <td>UKG</td>
                      <td>8</td>
                      <td>234</td>
                    </tr>
                    <tr>
                      <td>I</td>
                      <td>8</td>
                      <td>245</td>
                    </tr>
                    <tr>
                      <td>II</td>
                      <td>8</td>
                      <td>254</td>
                    </tr>
                    <tr>
                      <td>III</td>
                      <td>8</td>
                      <td>253</td>
                    </tr>
                    <tr>
                      <td>IV</td>
                      <td>9</td>
                      <td>284</td>
                    </tr>
                    <tr>
                      <td>V</td>
                      <td>8</td>
                      <td>235</td>
                    </tr>
                    <tr>
                      <td>VI</td>
                      <td>7</td>
                      <td>207</td>
                    </tr>
                    <tr>
                      <td>VII</td>
                      <td>7</td>
                      <td>207</td>
                    </tr>
                    <tr>
                      <td>VIII</td>
                      <td>5</td>
                      <td>152</td>
                    </tr>
                    <tr>
                      <td>IX</td>
                      <td>4</td>
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>X</td>
                      <td>4</td>
                      <td>95</td>
                    </tr>
                    <tr>
                      <td>XI</td>
                      <td>4</td>
                      <td>101</td>
                    </tr>
                    <tr>
                      <td>XII</td>
                      <td>2</td>
                      <td>49</td>
                    </tr>
                    {/* <tr>
                      <td></td>
                      <td><b>90</b></td>
                      <td><b>2674</b></td>
                    </tr> */}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default StudentStrength
