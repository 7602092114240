import { useEffect, useState } from "react";
import { getFaculty } from "../Service/Api";
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const OurFaculty = () => { 
  const [data, setData] = useState([]); 
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
      const fetchData = async () => {
          const data = await getFaculty(); 

          // Sort the data by class as numbers
          const sortedData = data.sort((a, b) => {
              return parseInt(a.class) - parseInt(b.class);
          });

          setData(sortedData);
          setFilteredData(sortedData); // Initially display all sorted data
      };
      fetchData();
  }, []);
  return (
    <>
    <HeadTag title="Our Faculty" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
     <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i> Our Faculty </span></h5>
        <h2>Our Faculty</h2>
        </div>
        <div className="innertable">
<table width="100%">
<tbody>
<tr>
<th>S. No.</th>
<th>Employee's Name</th>
<th>Designation</th>
<th>Category</th>
</tr>

{filteredData.length > 0 ? (filteredData.map((item, index) => (
  <tr key={index}>
    <td>{index + 1}</td>
    <td>{item.name}</td>
    <td>{item.designation}</td>
    <td>{item.qualification}</td>
  </tr>
 ))
 ) : (
  <tr>
  <td>1</td>
  <td>ABCD</td>
  <td>ABCD</td>
  <td>Manager</td>
  </tr>
 )}
{/* <tr>
<td>1</td>
<td>ABCD</td>
<td>ABCD</td>
<td>Manager</td>
</tr>
<tr>
<td>1</td>
<td>ABCD</td>
<td>ABCD</td>
<td>Manager</td>
</tr>
<tr>
<td>1</td>
<td>ABCD</td>
<td>ABCD</td>
<td>Manager</td>
</tr>
<tr>
<td>1</td>
<td>ABCD</td>
<td>ABCD</td>
<td>Manager</td>
</tr>
<tr>
<td>1</td>
<td>ABCD</td>
<td>ABCD</td>
<td>Manager</td>
</tr>
<tr>
<td>1</td>
<td>ABCD</td>
<td>ABCD</td>
<td>Manager</td>
</tr>
<tr>
<td>1</td>
<td>ABCD</td>
<td>ABCD</td>
<td>Manager</td>
</tr> */}
</tbody></table>
</div>
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default OurFaculty
