import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const AssessmentEvaluation = () => {
  return (
    <>
    <HeadTag title="Assessment & Evaluation" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Academics <i className="fas fa-angle-right"></i>  Assessment & Evaluation </span></h5>
        <h2> Assessment & Evaluation </h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
            <p>The academic session is divided into two terms.</p>      
            <p><b>First Term:</b> April to September</p>
            <p><b>Second Term:</b> October to March</p>
            <h3>CLASSES I-II</h3>
            <ul>  
            <li> The school follows the curriculum designed by the NCERT.</li>
            <li> The entire assessment process consists of a blend of different types of formative assessments wherein different domains of a child's learning are assessed.</li>
            <li>To ensure the holistic development of students and to reduce the burden on them, there are no examinations. Instead, three cycles of weekly assessments of 20 marks each are conducted throughout the year.</li>
            <li>Assessments also include Project Based Learning which is an art integrated, multi- disciplinary assessment of weightage 10% and is conducted in each term.</li>
            <li>Promotion is based on continuous and comprehensive evaluation.</li>
            </ul>
            <h3>CLASSES III-V</h3>
            <ul>  
            <li> The school follows the curriculum designed by the NCERT</li>
            <li> The session is divided into two terms.</li>
            <li> The assessment process comprises formative assessments and four cycles of weekly assessments which are conducted during the course of the year.</li>
            <li> Assessments also include multi-disciplinary and art integrated projects of weightage 10% and are conducted in each term.</li>
            <li> To ensure holistic development of students, co-scholastic activities are graded on a three point scale (A to C).</li>
            <li> Promotion based on continuous and comprehensive evaluation.</li>
            </ul>
            <h3>CLASSES VI-X</h3>
            <ul>
              <li>The session is divided into Pre Mid-Term, Mid-Term, Post Mid-Term and Annual Examinations.</li>
              <li>Internal assessments conducted on a regular basis, are of 20% weightage as per the CBSE guidelines, and these include:</li>
              <ol>
                <li>Periodic Tests</li>
                <li>Portfolio</li>
                <li>Subject Enrichment Activities</li>
                <li>Multiple Assessments</li>
              </ol>
              <li>In order to ensure holistic development of students, co-scholastic activities are graded on a five point scale (A to E) for classes IX and X, and three point scale for classes VI-VIII.</li>
              <li>Discipline significantly impacts one's life, career and character. Thus, discipline in terms of attendance, sincerity, behaviour and values is graded on a five point scale for classes IX and X, and three point scale for classes VI-VIII.</li>
              <li>It is mandatory for students to appear for all the Periodic Tests. </li>
            </ul>
            <h3>CLASSES XI-XII</h3>
            <ul>
              <li>The session is divided into two terms I and II Term.</li>
              <li>Regular assessments and Periodic Tests are conducted to assess the progress of students.</li>
              <li>It is mandatory to pass both the theory and practical examination, separately.</li>
            </ul>
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default AssessmentEvaluation
