import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
import LazyLoad from 'react-lazyload'
const AdvisoryMembers = () => {
  return (
    <>
    
    <HeadTag title="Advisory Board Members" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
     <div className="container-fluid flwidth board_members_sec">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i> Advisory Board Members </span></h5>
        <h2>Advisory Board Members</h2>
        </div>
      


         <div className="row">
         <div className="col-lg-6 col-md-6">
         <div className="advisory">
           <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/mrs-jaspreet-kaur.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
          <h4>Mrs. Jaspreet Kaur</h4>
          <p><strong>Member Managing Committee</strong></p>
          <p>Lotus Valley International School, Noida</p>
          <div className="clr"></div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6">
         <div className="advisory">
         <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/mrs-sureena-uppal.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
        <h4>Mrs. Sureena Uppal</h4>
               <p><strong>Member Managing Committee</strong></p>
        <p>Lotus Valley International School, Noida</p>
          <div className="clr"></div>
          </div>
          </div>
         </div>


         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/dr-harsh-mahajan.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Dr. Harsh Mahajan</h4>
       <p>A Padma Shri Awardee, Dr. Harsh Mahajan is a pioneer in the field of radiology and imaging. He set up one of India’s first privately run MRIs in 1991, and seven years later, was appointed as the honorary radiologist to the President of India, a post he continues to hold. He is the former president of the Indian Radiology and Imaging Association(IRIA), the Indian Society of Neuroradiology (ISNR), and he is also a consultant to the International Atomic Energy Association, Austria.</p>
       <p>He is currently the chief radiologist at Mahajan Imaging, the company he founded. Dr. Mahajan graduated from Maulana Azad Medical College, New Delhi, in 1982, after which he completed his post-graduation in Radiodiagnosis from the prestigious PGIMER, Chandigarh in 1986, he was awarded a rotary foundation fellowship at the MD Anderson Cancer Hospital and Research Institute in Houston, Texas. </p>
       
         <p></p>
         <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/anjum-chopra.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Ms. Anjum Chopra</h4>
       <p>Padma Shri and Arjuna  Awardee, Ms. Anjum Chopra is both a Test and One-Day International cricketer who represented the Indian Women’s Cricket team. She made her One Day International debut in 1995 against New Zealand at Christchurch, and her test debut a few months later against England at Eden Gardens, Kolkata. Ms Anjum was made the Captain of the Indian team in 2002. She was awarded the Honorary Membership of the Marylebone Cricket Club(MCC) of Lord’s Cricket Ground, London in 2016. She is the first Indian woman cricketer to be awarded this honour.  Ms. Anjum  has been recognized as the face of woman’s cricket in India as a player, captain, consultant, motivational speaker, author and actor. </p>
       
         <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/dr-sanjeev-bagai.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Prof. Dr. Sanjeev Bagai</h4>
       <p>Prof. Dr. Sanjeev Bagai is the Chairman and Managing Director of Nephron Health Care in Delhi and Vice-Chairman, Director and Dean of Dwarka Manipal Hospital. A Multi-faceted individual, Dr. Bagai is the leading Pediatrician and Pediatric Nephrologist of International repute. His prolonged efforts and foresightedness have been immensely critical in envisioning and instituting India’s first and largest Women’s and Children’s hospitals and Super Specialty Hospital- Medical Research Centre, in Delhi. He has collaborated with the largest Super Specialty University and Women and child network hospitals and chain of American University, UTMC, Ohio and partnered with Manipal Hospitals. He is a gold Medalist  from Bombay University, and also a Sir Dorab Tata Merit Scholar. He was awarded the Padma Shri in 2006 for his contributions in Indian Medical sector. The Dr. B.C Roy Award is one amongst the numerous prestigious awards bestowed on Dr. Bagai.</p>
         <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/anita-malhotra.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Mrs. Anita Malhotra</h4>
       <p>Mrs. Anita Malhotra is the Founder Principal of Lotus Valley International School, Gurugram which has been ranked amongst the top schools of the region by HT-C fore Top Schools Survey. A dynamic and progressive leader and strong administrator, Mrs. Malhotra is a stalwart in the field of education whose expertise is in setting up of multi-dimensional institutions from the inception. Mrs. Malhotra  brings with her a wealth of experience as the Founder Head of DPS Dwarka, DPS Vasant Vihar, Founder Principal DPS Dubai, Director DPS Bangkok and Principal Summer Fields School, Kailash Colony. The Delhi State and CBSE National Teacher’s Award, the Bharat Nirman Talented Ladies Award, the Rashtriya Gaurav Award are besides the 40 other awards bestowed on her for her immense contribution in the field of education. Under her dynamic leadership, Lotus Valley International School, Gurugram was declared as India’s First Best School in Future Readiness and Value Centricity. Her vision and leadership enabled Lotus Valley International School, Gurugram to win recognition as Asia’s Best and Fastest Growing Institute.</p>
       
         <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/mrs-lushin-dubey.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Mrs. Lushin Dubey</h4>
       <p>Mrs. Lushin Dubey is an Indian stage actor and director. Mrs. Dubey did her M.Sc. in Childhood and Special Education in the U.S. and has been deeply involved in teaching special children in the U.S. and Delhi. She has been directing, acting and scripting drama productions for over 30 years. She pioneered youth theatre in India and set up Kids World in 1987. Mrs. Lushin is known for for her solo plays, ‘Untitled’ and ‘Bitter Chocolate’ with Theatre Director Arvind Gaur. She has played the lead role in several television serials, including ‘Rajdhani’ and ‘Kashmir’. She acted in films like, ‘Partition’ (2007) and ‘Murder Unveiled’ 2005, for which she won the 2006 Gemini Award.</p>
       
         <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/punit-jain.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Mr. Punit Jain</h4>
       <p>Mr. Punit Jain is currently the Senior Vice President of The Times of India Group of Publications. He heads the Results and Market Development Department in the Northern Region. He was instrumental in the overall development of Navbharat Times, the leading Hindi daily of the group as the Publisher and Brand Manager. He gave a new dimension to the NIE ( Newspaper in Education) programme and launched it in a series of cities in India. Mr. Jain has facilitated social marketing to address public concerns, thereby adding a new dimension to Reader-Editorial interface and to the relevance of event marketing. Mr. Jain travels extensively for upgradation of information on education and newspaper technology, trends and related areas. He is a Board Member in several prestigious institutions including National Heart Institute, New Delhi, Times School of Journalism, and Indian Languages Newspaper Association.</p>
       
         <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/ruchi-seth.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Dr. Ruchi Seth</h4>
       <p>Dr Ruchi Seth, the Principal of Lotus Valley International School, Noida is a forward – thinking educator, an inspiring team leader, and a school administrator par excellence. Dr Seth holds a Ph.D. in Psychology from IIT Delhi. Her rich experience of three and a half decades in the education sector includes a stellar role as the Founder Principal of Delhi Police Public School. Dr Seth has been passionately involved in imparting pre-service training to teachers, forging international exchange programmes, organizing youth development activities, authoring books on Social Science and Value Education. She is the recipient of the CBSE National Best Teacher Award.  </p>
       
         <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/dr-Jitendra-nath-misra.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Dr. Jitendra Nath Misra</h4>
       <p>Dr Jitendra Nath Misra is a former IFS Officer (1982 batch) who has served as India’s Ambassador to Portugal and the Lao People’s Democratic Republic, and as India’s Consul General in Ho Chi Minh City. He has devised and taught courses on South Asia at the Masters and Bachelors levels in various universities across the world. Being a scholar diplomat, Dr Misra believes in the pursuit of scholastic and professional excellence within a liberal arts framework, and a celebration of diversity in a globalizing world. An avid sports enthusiast, Dr Misra was the Attache to the Indian contingent at the Manchester Commonwealth Games in 2002. He was the winner of the Diplomatic Tennis Tournament in Israel and a member of the Commonwealth cricket team in the U.K. For meritorious public service, Dr misra has received two commendations from the Government of India.</p>
        <div className="clr"></div>
         </div>
         </div>
         <div className="col-lg-12">
        <div className="advisory">
          <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/Sanjay-Sarin.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <h4>Mr. Sanjay Sarin</h4>
       <p>Mr. Sanjay Sarin has been practicing law since 1985, appearing before the Supreme Court of India and the High Courts of Delhi, Punjab & Haryana, and Bombay. Specializing in civil, finance, land & power litigation, he has handled over 1000 cases, with a focus on finance, infrastructure, power, trademarks, and commercial disputes.
</p>
       <p>He has represented major clients such as Alstom, Areva T&D, Bureau of Indian Standards (BIS), DLF Universal and Tata Consultancy Services in high-stakes arbitrations and litigations. Notably, he has advised electricity boards, including those of Madhya Pradesh and Chhattisgarh, and played a key role in disputes involving major infrastructure projects, such as the Vanpic project and Pizza Hut outlet developments in Mumbai.</p>
       <p>Mr. Sarin’s expertise extends to urban development, having advised the National Capital Region Planning Board on legal matters relating to land acquisition and township development, making him a highly respected legal advisor across various sectors.</p>
       
         <p></p>
         <div className="clr"></div>
         </div>
         </div>
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default AdvisoryMembers
