import React from 'react';
import { Link } from 'react-router-dom'
import Highlights from'../Component/Highlights'
import HomeNews from'../Component/HomeNews'
import HomeEvents from'../Component/HomeEvents'
import Testimonials from'../Component/Testimonials'
import Partners from'./Partners'
import HomeGallery from'../Component/HomeGallery'
import QuickGlancefirstslide from'./QuickGlancefirstslide'
import QuickGlancescdslide from'./QuickGlancescdslide'
import QuickGlancetrdslide from'./QuickGlancetrdslide'
import QuickGlancefourthslide from'./QuickGlancefourthslide'
import QuickGlancefifthslide from'./QuickGlancefifthslide'
import QuickGlancesixslide from'./QuickGlancesixslide'
import QuickGlanceseventhslide from'./QuickGlanceseventhslide'
import QuickGlanceEightslide from'./QuickGlanceEightslide'
import QuickGlancenineslide from'./QuickGlancenineslide'
import Footer from'../Component/Footer'
import Header from '../Component/Header';
import Campuscare from'../Component/Campuscare'
import OnlineReg from'../Component/OnlineReg'
import OnlineFee from'../Component/OnlineFee'
import Popup from '../Component/Popup'
import HeadTag from '../Component/HeadTags'
import LazyLoad from 'react-lazyload';
const Home = () => {
  return (
   <>
   <HeadTag title="Home" />
  <Popup />
   <Header/>
   <Campuscare/>
   <OnlineFee/>
   <div className="slider_sec">
     <div className="overlay_sec">
      <div className="logo_sec">
        <div className="container">
          <div className="col-lg-12">
              <Link to="/">
                <LazyLoad> <img src="Images/logo.jpg" className="img-fluid d-block mx-auto" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
              </Link>
          </div>
        </div>
      </div>
      <OnlineReg/>
    </div>
      <div className="pic-wrapper">
        <div className="slide pic-1"></div>
        <div className="slide pic-2"></div>
        <div className="slide pic-3"></div>
        <div className="slide pic-4"></div>
        <div className="slide pic-5"></div>
        <div className="slide pic-6"></div>
        <div className="slide pic-7"></div>
        <div className="slide pic-8"></div>
        <div className="slide pic-10"></div>
        <div className="slide pic-11"></div>
        <div className="slide pic-12"></div>
        <div className="slide pic-13"></div>
        <div className="slide pic-14"></div>
        <div className="slide pic-15"></div>
      </div>
  </div>
   <Highlights/>
   <div className="about_Sec">
      <div className="container">
         <div className="row">
          <div className="col-12 col-md-12 col-lg-8 col-xl-8 motto_sec">
            <h2>Lotus Valley International School</h2>
               <div className="row">
                    <div className="col-lg-6 col-md-6">
                         <div className="aboutbox">
                          <LazyLoad><img src="Images/lightbulb.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
                          <h3><Link to="/OurMotto">Our Motto</Link></h3>
                          <p>The motto of the school 'Arise Awake and Stop not till the Goal is Reached', taken from the clarion call of Swami Vivekanand </p>
                        
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                         <div className="aboutbox">
                         <LazyLoad>  <img src="Images/emblem.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
                          <h3><Link to="/OurEmblem">Our Emblem</Link></h3>
                          <p>The carefully chosen school insignia encompasses different aspects of learning: the global outlook necessary for the leaders of tomorrow, the book of knowledge, the torch of hope and integrity that ignites the minds of our young people and the Veena of Saraswati, the Hindu goddess of learning and speech.</p>
                       
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                         <div className="aboutbox">
                         <LazyLoad> <img src="Images/idea.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
                          <h3><Link to="/CorePhilosophy">Core Philosophy</Link></h3>
                          <p>“Education is the most powerful weapon which you can use to change the world.” Lotus Valley International School. </p>
                         
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                         <div className="aboutbox">
                         <LazyLoad> <img src="Images/vision.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
                          <h3><Link to="/MissionVision">Mission & Vision</Link></h3>
                          <p>“Education is teaching children how to think, not what to think.” – Ralph Waldo Emerson </p>
                         
                        </div>
                      </div>
               </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="row">
              <iframe width="100%" height="350" src="https://www.youtube.com/embed/QJTtoJgVaOM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
      </div>
   </div> 
</div>
<div className="event_importnt_sec">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-12 col-md-12 news_sec">
                <h2>Latest News <Link to="/News" className="readmore"> View all News <i className="fa fa-angle-right"> </i> </Link></h2>
                
                
             <marquee scrollamount="2" scrolldelay="30" direction="up" align="left">
                           <HomeNews/>
                    </marquee>
                  
              </div>
            <div className="col-xl-8 col-lg-12 col-md-12 event_sec">
              <div className="col-lg-12">
                <h2>Events & Celebrations <Link to="/Events" className="readmore"> View all Events <i className="fa fa-angle-right"> </i> </Link></h2>
              </div>
              <div className="row">
                   <HomeEvents/>
              </div>   
            </div>
          </div>
        </div>
 </div>
      <div className="testimonial_sec">
              <div className="container">
                   <div className="row">
                         <div className="col-lg-12">
                              <h2>Testimonials</h2>
                                <Testimonials/>
                                 
                                  <Link to="/Testimonial" className="readmore"> View all <i className="fa fa-angle-right"> </i> </Link>
                                 
                              <div className="partners_sec container">
                                  <div className="row">
                                      <div className="col-lg-12">
                                         
                                          <h2>Our Partners</h2>
                                         
                                    </div>
                                  </div>
                                    <Partners/>
                              </div>
                         </div>
                   </div>
              </div>
         </div>
         <div className="link_sec">
   <div className="container-fluid">
      <div className="row">
             <div className="col-lg-12">
                <h2>A Quick Glance</h2>
            
            </div>
            <div className="col-lg-4 col-md-6">
                 <QuickGlancefirstslide/>
            </div>
            <div className="col-lg-4 col-md-6">
               <QuickGlancescdslide/>
            </div>
            <div className="col-lg-4 col-md-6">
               <QuickGlancetrdslide/>
            </div>
            <div className="col-lg-4 col-md-6">
               <QuickGlancefourthslide/>
            </div>
            <div className="col-lg-4 col-md-6">
               <QuickGlancefifthslide/>
            </div>
            <div className="col-lg-4 col-md-6">
               <QuickGlancesixslide/>
            </div>
            <div className="col-lg-4 col-md-6">
              <QuickGlanceseventhslide/>
            </div>
            <div className="col-lg-4 col-md-6">
               <QuickGlanceEightslide/>
           </div>
            <div className="col-lg-4 col-md-6">
               <QuickGlancenineslide/>
           </div>
    </div>
  </div>
</div>
       <div className="gallery_sec">
         <div className="container">
            <div className="row">
                <div className="col-lg-12">
                   <h2>School Gallery</h2>
                </div>
            </div>
             <HomeGallery/>
        </div>
        <Link to="/PhotoGallery" className="readmore"> View all <i className="fa fa-angle-right"> </i> </Link> 
      </div>
    <Footer/>
   </>
  )
}

export default Home
