import React from 'react';
import { Helmet } from 'react-helmet'; 

const HeadTag = ({title}) => (
  <div>
    <Helmet>
      <title>Lotus Valley International School | {title}</title>
      <meta name="description" content="Lotus Valley International School in Greater Noida offers a world-class education with a focus on holistic development. Explore our state-of-the-art facilities, dedicated faculty, and innovative curriculum designed to nurture students' academic and personal growth. Learn more about our programs and admissions today!" />
      <meta name="google-site-verification" content="A6AjSsMZ2_o_g7fjoS9NYLI4uUYh2VasmUbIF8PQiIo" />
    </Helmet>
   
    

  </div>
);

export default HeadTag;
