import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="erp_links">
         <Link to="https://www.lviscampuscare.net" target="_blank">
            <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="Lotus Valley International School, Greater Noida"/></LazyLoad></Link>
      </div>
      </>
        
  )
}

export default Campuscare
