import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const Booklist = () => {
  return (
    <>
    <HeadTag title="Booklist" /> 
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  CBSE Documents  <i className="fas fa-angle-right"></i> Booklist</span></h5>
        <h2>Booklist</h2>
        </div>
        <div className="row">
        <div className="col-lg-6 col-md-12">         
        <h3>CLASS - PRE-NURSERY</h3>
        <ul>
        <li> Worlds of Wonder by Flying Saucer (set of 5 books)  </li>
        <li> Story book - Bubbles  </li>
        </ul>
        <h3>CLASS - NURSERY</h3>
        <ul>
        <li> Plant to Seedling (Set of 8 books) </li>
        </ul>
        <h3>CLASS - KG</h3>
        <ul>
        <li> Nature Nurture Kit (Set of 16 books)  </li>
        </ul>
        <h3>CLASS - I</h3>
        <ul>
        <li>Learning first kit Book Set (Set of 20 books) </li>
        </ul>
        <h3>CLASS - II</h3>
        <ul>
        <li>Learning first kit by Flying Saucer (Set of 23 books)</li>
        <li>Computer - IT Apps - 2 </li>
        </ul>
        <h3>CLASS - III</h3>
        <ul>
        <li>New Gems English Reader 3- Ratna Sagar</li>
        <li>Gems English Grammar 3- Ratna Sagar</li>
        <li>Frindle By Andrew Clements</li>
        <li>Vasundhra Hindi Pathmala 3 - Tarun Publication</li>
        <li>Vyakaran Nipun 3 - Tarun Publication</li>
        <li>Saras (Kahani Sangrah) 3 - Tarun Publication</li>
        <li>Living Maths 3 - Ratna Sagar</li>
        <li>Living Science 3 - Ratna Sagar</li>
        <li>IT Apps 3 - Kips Publication </li>
        <li>Articulate-3</li>
        </ul>
        <h3>CLASS - IV</h3>
        <ul>
        <li>New Gems English Reader 4- Ratna Sagar </li>
        <li>Gems English Grammar 4- Ratna Sagar </li>
        <li>Akbar & The Tricky Traitor - Natasha Sharma </li>
        <li>Vasundhra Hindi Pathmala 4 - Tarun Publication </li>
        <li>Vyakaran Nipun 4 - Tarun Publication </li>
        <li>Saras (Kahani Sangrah) 4 - Tarun Publication </li>
        <li>Living Maths Book 4 - Ratna Sagar </li>
        <li>Living Science Book 4 - Ratna Sagar </li>
        <li>My Big Book of Social Studies 4 - Ratna Sagar </li>
        <li>Oxford School Atlas - Oxford Printing Press </li>
        <li>IT Apps 4 - Kips Publication </li>
        <li>Articulate-4 </li>
        </ul>
        <h3>CLASS - V</h3>
        <ul>
        <li>New GEMS English Reader 5- Ratna Sagar </li>
        <li>GEMS English Grammar 5- Ratna Sagar </li>
        <li>Wingless - Paro Anand </li> 
        <li>Vasundhara Hindi Pathmala 5 - Tarun Publication </li>                          
        <li>Vyakaran Nipun 5 - Tarun Publication  </li>                                        
        <li>Saras (Kahani Sangrah) 5 - Tarun Publication</li>                                
        <li>Living Maths 5- Ratna Sagar </li>
        <li>Living Science 5 - Ratna Sagar </li>                                        
        <li>IT Apps 5 - Kips Publication </li>                                    
        <li>My Big Book of Social Studies 5- Ratna Sagar </li>           
        <li>Articulate - 5 </li>                        
        <li>Enchante'O - Rachna Sagar (French)</li>
        <li>Deutsch Und Ich by Goyal Publisher (German)</li>
        <li>Sanskrit Rashmi- Sultan Chand (Sanskrit)</li>
        <li>Abhyas Rashmi - Sultan Chand (Sanskrit)</li>
        </ul>
        <h3>CLASS - VI</h3>
        <ul>
        <li>New GEMS English Reader 6 - Ratna Sagar </li>
        <li>New GEMS English Workbook 6 - Ratna Sagar </li>
        <li>Iqbal - Francesco D Adams </li>
        <li>Vasant 6 – NCERT </li>
        <li>Manak Hindi Vyakaran Avm Sanrachna - 6 - Dr. Pratyush Gautam </li>
        <li>Balramkatha 6 – NCERT </li>
        <li>Mathematics 6 - NCERT </li>
        <li>Our Pasts History </li>
        <li>Social and Political civics </li>
        <li>The Earth Geography </li>
        <li>Living Science 6 - Ratna Sagar </li>
        <li>Computer learning- Sumita Arora </li>
        <li>Les Ailes 1- Mahita Ranjit (French) </li>
        <li>Hallo Deutsch 1 - Goyal Publisher (German) </li>
        <li>Ruchira 6 - NCERT(Sanskrit) </li>
        <li>Saraswati Manika Sanskrit Vayakaran- Sunita Sachdev (Sanskrit) </li>
        </ul>
        </div>
        
        <div className="col-lg-6 col-md-12">         
        <h3>CLASS - VII</h3>
        <ul>
        <li>New GEMS English Reader 7 - Ratna Sagar </li>
        <li>New GEMS English Workbook 7 - Ratna Sagar </li>
        <li>A Boy in Striped Pyjamas -John Boyne </li>
        <li>Vasant 7 – NCERT </li>
        <li>Manak Hindi Vyakaran Avm Sanrachna  </li>
        <li>Balmahabharat Sanchipth Katha 7 - NCERT </li>
        <li>Mathematics 7 – NCERT </li>
        <li>Our Pasts-II (History) 7 - NCERT </li>
        <li>Our Environment (Geography) 7 - NCERT </li>
        <li>Political Life-II(Civics) 7- NCERT </li>
        <li>Living Science 7 -Ratna Sagar  </li>
        <li>Computer Learning - Sumita Arora  </li>
        <li>Ailes 2 - Mahita Ranjit (French) </li>
        <li>Hello Deutsch 2 - Goyal Publishers (German) </li>
        <li>Ruchira 7 – NCERT (Sanskrit) </li>
        <li>Saraswati Manika Sanskrit Vayakaran- Sunita Sachdev (Sanskrit) </li>
        </ul>
        <h3>CLASS - VIII</h3>
        <ul>
        <li>New GEMS English Reader 8 - Ratna Sagar </li>
        <li>New GEMS English Workbook 8 - Ratna Sagar </li>
        <li>I am Tamana - By Tamana Chona </li>
        <li>Vasant 8 – NCERT </li>
        <li>Manak Hindi Vyakaran Avm Sanrachna  </li>
        <li>Sanchipth Buddha Charitam 8- Rachna Sagar  </li>
        <li>Mathematics 8 – NCERT </li>
        <li>Our Pasts-III (History) 8 - NCERT </li>
        <li>Resources and Development (Geography)8 - NCERT </li>
        <li>Political Life-III(Civics) 8 - NCERT </li>
        <li>Living Science 8 - Ratna Sagar </li>
        <li>Computer Learning- Sumita Arora </li> 
        <li>Les Ailes -3 - Mahita Ranjit </li> 
        <li>Hello Deutsch 3 - Goyal Publisher </li>
        <li>Ruchira 8 – NCERT </li>
        <li>Saraswati Manika Sanskrit Vayakaran- Sunita Sachdev </li>
        </ul>
        <h3>CLASS - IX</h3>
        <ul>
        <li>English- Beehive Text Book 9 - NCERT </li>
        <li>English- Moments Supplementary Reader 9 - NCERT </li>
        <li>Mathematics 9- NCERT </li>
        <li>Maths Exempler 9- NCERT </li>
        <li>Democratic Politics 9- NCERT </li>
        <li>Economics 9 – NCERT </li>
        <li>Contemporary India 1- NCERT </li>
        <li>India & The Contemporary World History </li>
        <li>Science 9 -NCERT </li>
        <li>Science Lab Manual 9 (Comprehensive) </li>
        <li>Science Exempler 9-NCERT </li>
        <li>Computer Applications by Sumita Arora (Code No. 165) </li> 
        <li>Entire Jeunes – NCERT (French) </li>
        <li>Get Ready 9 - Goyal Publishers (French) </li>
        <li>Beste Freunde - Goyal Publishers (German) </li>
        <li>Sparsh 1(NCERT) (Hindi) </li>
        <li>Sanchayan 1 (NCERT) (Hindi) </li>
        <li>Mein Aur Mera Vyakaran - Saraswati Publication (Hindi) </li>
        <li>Shemushi -NCERT (Sanskrit) </li>
        <li>Abhyas Pustika-NCERT(Sanskrit) </li>
        </ul>  
        </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Booklist
