import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import CommitteeDetailsData from'./CommitteeDetailsData'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const CommitteeDetails = () => {
  return (
    <>
    <HeadTag title="Committee Details" /> 
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> CBSE Documents <i className="fas fa-angle-right"></i>  Committee Details </span></h5>
        <h2> Committee Details</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 trainers"> 
                 
             
            <div class="accordion accordion-flush" id="accordionFlushExample1">
               <div class="accordion-item">
<h2 class="accordion-header" id="flush-headingThrteen1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThrteen1" aria-expanded="false" aria-controls="flush-collapseThrteen1">
 POCSO Committee 
</button>
</h2>
<div id="flush-collapseThrteen1" class="accordion-collapse collapse" aria-labelledby="flush-headingThrteen1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
  <div className="innertable">
            <table width="100%" cellpadding="0" cellspacing="0">
        <thead>
          
        </thead>
        <tbody>
               <tr>
    <td width="50%">  School Principal </td> <td> Ms. Indu Yadav </td>
    </tr>
     <tr>
    <td> Head, Pre-Primary Wing </td> <td>  Ms. Gagandeep Kaur </td>
    </tr>
     <tr>
    <td> Primary Coordinator </td> <td>  Ms. Darshana Menon </td>
    </tr>
     <tr>
    <td> HR </td> <td>  Ms. Anita K Joshi </td>
    </tr>
     <tr>
    <td> Teacher Representative (Female) </td> <td>  Ms. Niketa Sareen </td>
    </tr>
     <tr>
    <td> Teacher Representative (Male) </td> <td>  Ms. Zaheer Abbas </td>
    </tr>
     <tr>
    <td> Head, Student Welfare </td> <td>  Ms. Neha Johar </td>
    </tr>
     <tr>
    <td> Student Representative (Female) </td> <td>  Ms. Anusha Singh </td>
    </tr>
     <tr>
    <td> Student Representative (Male) </td> <td>  Mr. Angad Singh </td>
    </tr>
     <tr>
    <td> Parent Representative </td> <td>  Mr. Amit Rai Ahuja </td>
    </tr>
     <tr>
    <td> Advocate-Supreme Court </td> <td>  Ms. Gagandeep Kaur </td> 
    </tr>
       
        </tbody>
      </table>
                </div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingTwel1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwel1" aria-expanded="false" aria-controls="flush-collapseTwel1">
 Grievance/ Complaint Redressal Committee 
</button>
</h2>
<div id="flush-collapseTwel1" class="accordion-collapse collapse" aria-labelledby="flush-headingTwel1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
  <div className="circular_sec">
              <div className="innertable">
                         <table width="100%" cellpadding="0" cellspacing="0">
                     <thead>
                       
                     </thead>
                     <tbody>
                 <tr>
                <td width="50%">Management Representative </td> <td> Ms. Sureena Uppal </td> 
             </tr> 
             <tr>
              <td> School Principal </td> <td>  Ms. Indu Yadav  </td> 
             </tr> 
             <tr>
              <td> Teacher Representative </td> <td>  Ms. Neha Johar </td> 
             </tr> 
             <tr>
              <td> Staff Representative  </td> <td> Ms. Rajvinder Kaur </td> 
             </tr> 
             <tr>
              <td> Parent Representative  </td> <td> Mr. Amit Rai Ahuja </td> 
             </tr> 
             <tr>
              <td> Parent Representative </td> <td>  Mr. Kapil Shandilya </td> 
             </tr> 
             <tr>
              <td> Student Representative </td> <td>  Ms. Kasshvi Saxena </td> 
             </tr> 
             <tr>
              <td> Student Representative  </td> <td> Mr. Saksham Chaudhary </td> 
             </tr>
                    
                     </tbody>
                   </table>
                             </div>
             </div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingElev1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseElev1" aria-expanded="false" aria-controls="flush-collapseElev1">
 Anti-Bullying Committee 
</button>
</h2>
<div id="flush-collapseElev1" class="accordion-collapse collapse" aria-labelledby="flush-headingElev1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div className="circular_sec">
            <div className="innertable">
                       <table width="100%" cellpadding="0" cellspacing="0">
                   <thead>
                     
                   </thead>
                   <tbody>
           
                      <tr> <td width="50%">Management Representative  </td> <td> Ms. Jaspreet kaur </td> </tr> 
           <tr> <td> School Principal </td> <td> Ms. Indu Yadav </td> </tr> 
           <tr> <td> Senior Teacher </td> <td> Ms. Deepika Sharma </td> </tr> 
           <tr> <td> School Doctor </td> <td> Dr. Chitranshi </td> </tr> 
           <tr> <td> Head, Student Welfare </td> <td> Ms. Neha Johar </td> </tr> 
           <tr> <td> Parent Representative </td>  <td>Mr. Amit Rai Ahuja </td> </tr> 
           <tr> <td> Legal Representative  </td> <td> Mr. Taruneshwar Nath Mathur </td> </tr> 
           <tr> <td> Peer Educator </td> <td> Ms. Kasshvi Saxena </td> </tr>
                  
                   </tbody>
                 </table>
                           </div>
           </div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingTen1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen1" aria-expanded="false" aria-controls="flush-collapseTen1">
 School Safety Committee 
</button>
</h2>
<div id="flush-collapseTen1" class="accordion-collapse collapse" aria-labelledby="flush-headingTen1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div className="circular_sec">
 <div className="innertable">
            <table width="100%" cellpadding="0" cellspacing="0">
        <thead>
          
        </thead>
        <tbody>          
           <tr> <td width="50%"> School Principal</td> <td> Ms. Indu Yadav </td> </tr> 
<tr> <td> Head, Pre-Primary</td> <td> Ms. Gagandeep Kaur </td> </tr> 
<tr> <td> Middle School Incharge</td> <td> Ms. Tulika Ranjan </td> </tr> 
<tr> <td> Primary Incharge</td> <td> Ms. Darshana Menon </td> </tr> 
<tr> <td> Senior Wing Incharge</td> <td> Ms. Sheetal Mittal </td> </tr> 
<tr> <td> School Girls’ Captain</td> <td>  Ms. Anusha Singh </td> </tr> 
<tr> <td> School Boys’ Captain</td> <td>  Mr. Angad Singh Amole </td> </tr> 
<tr> <td> Parent Representative</td> <td> Mr. Amit Rai Ahuja </td> </tr> 
<tr> <td> Head, Student Welfare</td> <td> Ms. Neha Johar </td> </tr> 
<tr> <td> Admin Representative</td> <td> Mr. Ashraf Ullah Khan </td> </tr>
     
        </tbody>
      </table>
                </div>
</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingNine1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine1" aria-expanded="false" aria-controls="flush-collapseNine1">
 POSH Committee 
</button>
</h2>
<div id="flush-collapseNine1" class="accordion-collapse collapse" aria-labelledby="flush-headingNine1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div className="circular_sec">
 <div className="innertable">
            <table width="100%" cellpadding="0" cellspacing="0">
        <thead>
          
        </thead>
        <tbody>          
           <tr> <td width="50%"> Chairperson </td> <td> Ms. Gagandeep Kaur (Head Pre-Primary)</td> </tr> 
<tr> <td> Member 1</td> <td>  Ms. Darshana Menon (Primary Coordinator)</td> </tr> 
<tr> <td> Member 2 </td> <td> Ms. Neha Johar (Head, Student Welfare)</td> </tr> 
<tr> <td> Member 3 </td> <td> Mr. Amit Rai Ahuja (Parent Representative)</td> </tr> 
<tr> <td> Member 4 </td> <td> Mr. Mayur Gupta (Admin Representative)</td> </tr> 
<tr> <td> Member 5</td> <td>  Ms. Geetanjali Solanki (External Consultant)</td> </tr>     
        </tbody>
      </table>
                </div>
</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingEight1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight1" aria-expanded="false" aria-controls="flush-collapseEight1">
 School Disaster Management Response Team 
</button>
</h2>
<div id="flush-collapseEight1" class="accordion-collapse collapse" aria-labelledby="flush-headingEight1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div className="circular_sec">
 <div className="innertable">
            <table width="100%" cellpadding="0" cellspacing="0">
        <thead>
          
        </thead>
        <tbody>          
          <tr> <td width="50%"> Chairperson:</td> <td>  Ms. Indu Yadav, Principal </td> </tr> 
<tr> <td> Secretary:</td> <td> Ms. Darshana Menon, Primary Wing Coordinator </td> </tr> 
<tr> <td> Students:</td> <td>  Ms. Kasshvi Saxena (IX-Oak),  Mr. Aaujas Garg (IX Oak) </td> </tr> 
<tr> <td> Community Members:</td> <td>  Dr. Aprajita Shaw (Parent), 16 Dental Avenue Clinic </td> </tr> 
<tr> <td> Community Member:</td> <td>  Dr. Kanika Gera Thakral (Parent), Blessing Poly Clinic </td> </tr> 
<tr> <td> Community Member:</td> <td>  Ms. Nidhi Jasrai, Teacher-Pre-Primary Wing </td> </tr> 
<tr> <td> School Focal Head: </td> <td> Ms. Gagandeep Kaur, Head Pre-Primary </td> </tr>    
        </tbody>
      </table>
                </div>
</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingSeven1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven1" aria-expanded="false" aria-controls="flush-collapseSeven1">
 School Transport Safety Committee 2024-25 
</button>
</h2>
<div id="flush-collapseSeven1" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div className="circular_sec">
 <div className="innertable">
            <table width="100%" cellpadding="0" cellspacing="0">
        <thead>
          
        </thead>
        <tbody>          
            <tr> <td width="50%">  Principal </td> <td> Ms. Indu Yadav </td> </tr> 
<tr> <td> Transport Head </td> <td> Mr. Lek Raj Chauhan </td> </tr> 
<tr> <td> Transport Supervisor </td> <td> Mr. Sumit Bhati </td> </tr> 
<tr> <td> Admin Executive</td> <td>  Ms. Aruna Singh</td> </tr> 
<tr> <td> Head - Pre Primary Wing</td> <td>  Ms. Gagandeep Kaur</td> </tr> 
<tr> <td> Head - Primary Wing</td> <td>  Ms. Darshana Menon </td> </tr> 
<tr> <td> Head - Middle Wing</td> <td> Ms. Tulika Ranjan</td> </tr> 
<tr> <td> Head - Senior Wing</td> <td> Ms. Gurdeep Kaur </td></tr>  
        </tbody>
      </table>
                </div>
</div>
</div>
</div>
</div> 
</div> 
            </div>
        </div>
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default CommitteeDetails
