import React, { useEffect, useState } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import { getTestimonial } from '../Service/Api';
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const Testimonial = () => {
  
  const [data, setData] = useState([]);
  useEffect(() => {
      const getData = async () => {
          const data = await getTestimonial();
          console.log(data);
          setData(data);
      };
      getData();
  }, []);

  const emptyArray = [
    { description: "Stay tuned for latest updates" }, 
];
 
  return (
    <>
    <HeadTag title="Testimonial" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth testi_sec">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Testimonial</span></h5>
        <h2>Testimonial</h2>
        </div>
          <Tabs className="Tabs">
            <TabList>
                <Tab>Testimonials</Tab>
                <Tab>Video Testimonials</Tab> 
            </TabList>
            <TabPanel>
            {data.length > 0 ? (
    data.map((item, index) => (
               <div className="testimonialsbox">
                     <p>{item.discription}</p>
                     <h4>{item.name}</h4>
            
                </div>
                 ))
                 ) : (
                   emptyArray.map((data, index) => (
                    <div className="testimonialsbox">
                <p>We just wanted to reach out to you all today, and let you know how amazing a job all of you are doing. These days of e-learning have allowed us parents to observe the efforts put in by all of you from close quarters. We appreciate how you've tirelessly ventured out into this whole new world, reaching out to the kids with patience, warmth and love. We are so so grateful.</p>
                <h4>Radhika and Sarvesh, Parents of Saumyaa (grade 3 Alex) and Raghu (grade 6 Zeus)</h4>
       
                </div> 
                   ))
                 )}
                {/* <div className="testimonialsbox">
                <p>We just wanted to reach out to you all today, and let you know how amazing a job all of you are doing. These days of e-learning have allowed us parents to observe the efforts put in by all of you from close quarters. We appreciate how you've tirelessly ventured out into this whole new world, reaching out to the kids with patience, warmth and love. We are so so grateful.</p>
                <h4>Radhika and Sarvesh, Parents of Saumyaa (grade 3 Alex) and Raghu (grade 6 Zeus)</h4>
       
                </div> */}
            </TabPanel>
            <TabPanel>
                 <div className="row">
                 {/* <div className="col-lg-4 col-md-6">
                      <div className="testimonialsbox testimonialsvideobox">
                        <iframe width="100%" height="250" src="https://www.youtube.com/embed/8ZAcDnqN7wg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <h4>Radhika and Sarvesh, Parents of Saumyaa (grade 3 Alex) and Raghu (grade 6 Zeus)</h4>
                         
                       </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                      <div className="testimonialsbox testimonialsvideobox">
                        <iframe width="100%" height="250" src="https://www.youtube.com/embed/Y1aU8wE5SEY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <h4>Testimonial Grade Nursery</h4>
                         
                       </div>
                    </div>
                 </div>
            </TabPanel> 
           </Tabs>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Testimonial
