import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'

import HeadTag from '../Component/HeadTags'
const ITELearning = () => {
  return (
    <>
    <HeadTag title="IT & E-learning" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Academics  <i className="fas fa-angle-right"></i>   IT & E-learning </span></h5>
        <h2>  IT & E-learning </h2>
        </div>
        <p>The school has IT enabled smart classrooms where teaching learning process is carried out leveraging technology.</p>
        <div className="row">
        <div className="col-lg-6 col-md-12">
        <h3>CampusCare is Interactive online portal where teachers, parents and students can access varied services that include:</h3>
        <div className="clr10"></div>
        <ul>
          <li> Essential student data (accessible to each parent individually) </li>
                    <li> Daily attendance </li>
                    <li> Class time-table </li>
                    <li> Yearly syllabus </li>
                    <li> Circulars, tests schedule, proposed events and holidays list </li>
                    <li> School results </li>
                    <li> Daily updates of Class/Home Work </li>
                    <li> Self learning assignments </li>
                    <li> Online registration for admissions </li>
                        </ul></div>
                        <div className="col-lg-6 col-md-12">
                        <h3>The school has an Ultra HD Dynamic Website with the following facilities:</h3>
                        <div className="clr10"></div>
                    <ul>
                        <li> Link to School ERP (CampusCare) </li>
                <li> CampusCare Parent Helpdesk </li>
                <li> Link to download CampusCare mobile app </li>
                <li> New updates, circulars, results and events </li>
                <li> Image Gallery </li>
                <li> Video Gallery </li>
                <li> Media coverage </li>
                <li> Online registration for admissions etc.</li>
                        </ul>
        </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default ITELearning
