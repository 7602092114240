import React, { useState } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
import LazyLoad from 'react-lazyload';
const OurAchievements = () => {
 
  return (
    <>
    <HeadTag title="Our Achievements" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth achivemnt_sec">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  About Us <i className="fas fa-angle-right"></i>  Our Achievements</span></h5>
        <h2>Our Achievements</h2>
        </div>
        <Tabs className="Tabs">
        <TabList>
          <Tab>2024 - 25</Tab>
          <Tab>2022 - 23</Tab>
          <Tab>2021 - 22</Tab>
          <Tab>2020 - 21</Tab>
          <Tab>2019 - 20</Tab>
          <Tab>2018 - 19</Tab>
      </TabList>
      <TabPanel>
      <div class="row">
      <div class="col-lg-12 col-md-12">
      
       <div class="achievementbox no-scroll">
       <div class="main">
      <p><b>Educational Excellence Award</b> by GSLC SPARC School on 13th January 2024.<br></br>Principal of the Year 2024’ at the 10th Eduleaders Summit &amp; Awards on January 22, 2024, by the Universal Mentors Association powered by Brain Wonders.
</p>
<div className='row'>

<div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
  <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-13-01-2024-1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
  </div>
  <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
<LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-13-01-2024-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
  </div> 
  <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
  <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-13-01-2024-3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
  </div> 
  <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
<LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-13-01-2024-4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
  </div> 
  </div>
      </div>
          
      <div class="clr"></div>
      </div>
      </div>
     
      <div class="col-lg-12 col-md-12">
       <div class="achievementbox no-scroll">
       <div class="main">
      <p><b>Outstanding Principal</b> of the Year by the Economic Times Education Excellence Awards ‘24<br></br><b>Best School</b> for Innovation in Pedagogical Initiatives by the Economic Times Education Excellence Awards ‘24</p>
      </div>
        <div className='row'>

        <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
        <LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-18-09-2024-1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
          </div>
          <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
          <LazyLoad>    <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-18-09-2024-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
          </div>
          <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
          <LazyLoad>   <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-18-09-2024-3.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
          </div>
          <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
          <LazyLoad>   <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-18-09-2024-4.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
          </div>
          </div>
        
    
      <div class="clr"></div>
      </div>
      </div>
      </div>
       </TabPanel>
       <TabPanel>
      <div class="row">
      <div class="col-lg-4 col-md-6">
       <div class="achievementbox no-scroll ">
      <LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-13-10-2023.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
        
      <div class="main">
      <p><b>‘The Leading Co-ed Day School Award’ </b> for the Greater Noida region, Education World India School Ranking on 13.10.2023.</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-6">
       <div class="achievementbox no-scroll">
         <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/global-edu-award.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad> 
        
      <div class="main">
      <p><b>Global Educator Award</b> for Promoting academic Excellence 2022-23 by International Olympiad Foundation (IOF) (for Session 2022-23)</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-6">
       <div class="achievementbox no-scroll">
        <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/special-merit-award.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>  
        
      <div class="main">
      <p><b>Special Merit Award</b> for Digital Innovation Award by Education World Learning Exposition 2023 (for Session 2022-23)</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>

      </div>
       </TabPanel>
      
       <TabPanel>
      <div class="row">
      <div class="col-lg-6 col-md-6">
       <div class="achievementbox">
         <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-02-04-2022.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
        
      <div class="main">
      <p>LVISNE was honoured with the award for <b> ‘Best Experiential Learning Implementation in School’</b> at the Experiential Learning Awards 2022.</p>
      </div>
      <div class="clr"></div>
      </div>
      </div> 
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
        <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-21-11-2022.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>Manifesting Inspiration - <b>‘SDG Hero by Global Sustainability Awards – 2022’</b>.</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      </div>
       </TabPanel>
       <TabPanel>
      <div class="row">
      <div class="col-lg-6 col-md-6">
       <div class="achievementbox">
        <div class="imgdiv">
         <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-21-09-2021.png" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad> 
         <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-21-09-2021-1.png" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
        </div>
      <div class="main">
      <p><b>ICONIC WOMEN LEADER AWARD-2021, BY ASIA GCC AWARDS</b><br/>It gives us immense pleasure to share that, Ms. Indu Yadav, Principal, Lotus Valley International School, Noida Extension has been conferred the Iconic Women Leader Award-2021, by ASIA GCC Awards in the category of Education. The forum felicitated women achievers from diverse fields of Medicine, Aviation, Health Care, Environment, Entrepreneurship and Education, in a virtual ceremony held on September 4, 2021. <br/>
      The school is honoured and proud of Mrs. Yadav’s accomplishment. </p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
        
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
      <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-03-03-2020.jpeg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"Lotus Valley International School, Noida Extension has added another feather to the cap by winning the award for “Best School in Teaching- Learning Practices” at the Indian Excellence in Education Awards 2020 on 29.02.2020 organized by My Brand Better."</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      </div>
       </TabPanel>
       <TabPanel>
       <div class="achievementbox no-scroll">
       <div class="main">
      <p><b>Top 100 Effective Principal Award</b>-2019 by AKS Education Award</p>
<div className='row'>

<div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
 <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/AKS-1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
  </div>
  <div className='col-xxl-3 col-lg-3 col-md-6 col-sm-6-col-xs-12'>
  <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/AKS-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
  </div>  
  </div>
      </div>
          
      <div class="clr"></div>
      </div>
        <div class="row">
          
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-26-02-2020.jpeg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"LVIS, NE has been recognised as the "Best Emerging CBSE School in Innovative Practices, Co-Curricular Activities & Life Skill Education" by Brainfeed School Excellence Awards for the academic session 2019-20."</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-28-01-2020-1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"LVIS NE adds yet another feather to its already adorned cap by winning the award for being an 'Exceptional School in Art Integrated Curriculum' by AKS Global Education Awards"</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-28-01-2020-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"LVIS NE adds yet another feather to its already adorned cap by winning the award for being an 'Exceptional School in Art Integrated Curriculum' by AKS Global Education Awards"</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-21-01-2020-1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>Lotus Valley International School, Noida Extension has been awarded as ‘Best Upcoming CBSE School 2019-20 awarded by India Merit School award’  by Education Today.</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-21-01-2020-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>Lotus Valley International School, Noida Extension has been awarded as ‘Best Upcoming CBSE School 2019-20 awarded by India Merit School award’  by Education Today.</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award23-9-19-2.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>Lotus Valley International School, Noida Extension has been awarded as ‘Most Innovative Pedagogical Practices in Noida Extension’ by India Education Awards 2019. </p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <div class="imgdiv">
       <LazyLoad>  <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award23-9-19.jpg" width="400" height="300" layout="responsive" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       <LazyLoad>     <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award23-9-19-1.jpg" width="400" height="300" layout="responsive" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
       </div>
      <div class="main">
      <p>Lotus Valley International School, Noida Extension has been awarded as ‘Most Innovative Pedagogical Practices in Noida Extension’ by India Education Awards 2019. </p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      </div>
       </TabPanel>
       <TabPanel>
        <div class="row">
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award25-4-19.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>High Performing School of 2018 by AKS Education Awards on 25 February, 2019. </p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award-1-2-19.png" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p> Best School for Academic Excellence’ by Go4Reviews-School <strong>Brainer School Excellence Award 2018</strong></p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award2-1-2-19.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"Lotus Valley International School, Noida Extension awarded as the Best CBSE School in Co-Curricular Activities &amp; Innovative Practices by Brainfeed <strong>School Excellence Awards 2018-19</strong>"</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/award3-1-2-19.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"Lotus Valley International School, Noida Extension awarded as the Best CBSE School in Co-Curricular Activities &amp; Innovative Practices by Brainfeed <strong>School Excellence Awards 2018-19</strong>"</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/topcbse.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"The school has been awarded as Top CBSE School in Uttar Pradesh for <strong>‘Individual Attention to Students’</strong> by Education Today. "</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
       <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/achv-award.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
      
      <div class="main">
      <p>"The school has been awarded as Top CBSE School in Uttar Pradesh for <strong>‘Individual Attention to Students’</strong> by Education Today. "</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
       <div class="achievementbox">
        <div class="imgdiv">
        <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/awarimg.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
        <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/awarimg1.jpg" class="img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
        </div>
      <div class="main">
      <p> Lotus Valley International School, Noida Extension has been awarded as the <strong>‘Best Emerging School in NCR’</strong> by the India Leadership Awards at a ceremony held at Bangalore on 07 October, 2018.</p>
      </div>
      <div class="clr"></div>
      </div>
      </div>
      </div>
       </TabPanel>
      </Tabs>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default OurAchievements
