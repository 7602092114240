import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
const QuickGlancescdslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
        <div className="draw-border">
        <h3><Link to="Sports">Sports</Link></h3>
        <p>Physical fitness is one of the most important elements of leading a healthy life. Physical education promotes the importance of inclusion of a regular fitness activity in the routine. This helps the students to maintain their fitness, develop their muscular strength, increase their stamina and thus stretch their physical abilities to an optimum level. Sports help inculcate discipline, self confidence, team spirit, sense of belonging, pride, achievement and self esteem. Students also understand that victory and defeat is an ongoing process.</p>
        <div className="clr"></div>
      </div>
        </div>
        <div className="item">
           <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/school.jpg" className="image img-fluid" alt="Lotus Valley International School, Greater Noida"/>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlancescdslide;
