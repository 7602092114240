import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
import LazyLoad from 'react-lazyload'
const CorePhilosophy = () => {
  return (
    <>
    <HeadTag title="Core Philosophy" /> 
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Core Philosophy </span></h5>
        <h2> Core Philosophy</h2>
        </div>
           <div className="row">
                 <div className="col-lg-12 col-md-12">
                 <p>
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/core.jpg"  className="inner_img img-fluid" alt="Lotus Valley International School, Greater Noida"/></LazyLoad></p>
                 <p>Lotus Valley International School is founded on the philosophy of <strong>'The Natural Way'</strong>. Every student is treated as an individual with distinctive learning inclinations and unique abilities. The lotus underlines Lotus Valley International School's central philosophy of eliciting the best from each student so that all can rise above the quagmire of modern day negativity and pessimism, just as the lotus thrusts itself upwards from the mire in an act of beautiful transcendence. </p>
                 <p>We embrace the natural path of education in a green and peaceful environment and aim to recognize every child's talent and potential and equip them with opportunity and character to nurture their unique ability. </p>
                 <p>We believe that education is not a static condition, but an evolving process of igniting minds, touching hearts, joining hands, enjoying similitudes and celebrating differences. The school thus becomes the haven where young souls learn to discover and hone what is within, and aspire for what is without, and the teaching-learning process thus becomes the touchstone that transforms a child into a complete individual. Committed advocates of the Theory of Multiple Intelligences, we at LVIS believe education is about not just scholastic achievement, but equally about holistic development of the body, mind, soul and intellect, and that every talent nurtured is a spirit evolved to its zenith.</p>
                 
                 </div>
           </div>
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default CorePhilosophy
