import React, { useEffect, useState } from "react";
import { getOnlineRegistration } from "../Service/Api";
import { Link } from 'react-router-dom'
const OnlineReg = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
   const fetchData = async () => {
     try {
       const data = await getOnlineRegistration();
       setData(data);
     } catch (error) {
       console.error("Error fetching online registration data:", error);
     }  
   };
   fetchData();
 }, []);
 const emptyArray =[]
  return (
    <>
     {data.length > 0 ? (
            data.map((item, index) => (
    <div class="online_reg"><ul><li><h3><Link to="https://www.lviscampuscare.net/" target="_blank">{item.title}</Link></h3></li></ul></div>
    ))
    ) : (
      emptyArray.map((item, index) => <div key={index}></div>)
    )}
    </>
  )
}

export default OnlineReg
