import React, { useEffect, useState } from "react";
import { getEventsinner } from "../Service/Api";
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
import LazyLoad from "react-lazyload";
const Events = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Events - Presentation Convent School Delhi';  
      }, []);
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getEventsinner();
            setData(galleryData);

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const uniqueMonths = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const month = new Date(item.date).getMonth();
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const monthOptions = uniqueMonths.sort((a, b) => a - b).map(month => monthNames[month]);
            setMonthOptions(["All", ...monthOptions]);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    return parseInt(selectedYear) === year;
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth();
                    return monthNames[month] === selectedMonth;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sort the filtered data by month in descending order
        filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
  return (
    <>
    <HeadTag title="Events" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Events </span></h5>
        <h2> Events</h2>
        </div>
        <div className="row tabs-dynamic">
        <div className="col-md-3">
            <div className="count-val">
                <p>Total Count:  {filterData.length}</p>
            </div>
        </div>
        <div className="col-md-3">
            <div className="month-selection">
            <select  
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
            </div>
        </div>
        <div className="col-md-3">
            <div className="month-selection">
            <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect"> 
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
            </div>
        </div>
        <div className="col-md-3">
            <div className="searchBox">
            <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
            </div>
        </div>
    </div>
    <div className="row event_news_inner">
    {filterData.length > 0 ? (
                           filterData.map((item, index) => (
          <div className="col-lg-6 col-md-12" key={index}>
                <div className="data_sec">
                    <div className="overlay_news">
                        <p>{item.description}</p>
                    </div>
                    <div className="date_hidn">
                 <LazyLoad>   {item.images.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item?.images}`} alt="Lotus Valley International School, Greater Noida"/>):(<img src="https://webapi.entab.info/api/image/LVISGN/public/Images/events.jpg" alt="Lotus Valley International School, Greater Noida"/>)}</LazyLoad>
                    {item.attachments > 0 && (<div class="attach_sec"><Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank"><i class="fa fa-paperclip" aria-hidden="true"></i></Link></div>)}
                    </div>
                    <div className="data_scrol">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    
                    </div> 
                    <ul><li><i class="fa fa-clock" aria-hidden="true"></i> &nbsp;{item.time}</li><li><i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;{item.date}</li><li><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; {item.venue}</li></ul>
                     </div>
            </div> 
               ))
               ) : (
                 
                    <div className="col-lg-6 col-md-12">
                    <div className="data_sec">
                        <div className="overlay_news">
                            <p>Stay tuned for more information about latest updates.</p>
                        </div>
                        <div className="date_hidn">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LVISGN/public/Images/events.jpg" alt="Lotus Valley International School, Greater Noida"/></LazyLoad>
                        <div class="attach_sec"><Link to="/" target="_blank"><i class="fa fa-paperclip" aria-hidden="true"></i></Link></div>
                        </div>
                        <div className="data_scrol">
                            <h3>Events Heading - 1</h3>
                            <p>Stay tuned for more information about latest updates.</p>
                        
                        </div> 
                        <ul><li><i class="fa fa-clock" aria-hidden="true"></i> &nbsp;09:30</li><li><i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;01 Jun 2024</li><li><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; GD GOENKA, GHAZIABAD</li></ul>
                        </div>
                </div>  
               )}

           
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Events
